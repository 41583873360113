var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "px-6 pb-4",
          attrs: { elevation: "2", color: "primary", rounded: "lg" },
        },
        [
          _c(
            "v-tabs",
            {
              attrs: { right: "", "background-color": "primary", dark: "" },
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            [
              _c(
                "v-tab",
                { key: "1" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-leaf-circle-outline"),
                  ]),
                  _vm._v(" Debit Note "),
                ],
                1
              ),
              _vm.debitInfo.searchingType === "BILL_LADING_NO"
                ? _c(
                    "v-tab",
                    { key: "2" },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-bullseye"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("text-notice-of-arrival")) + " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "mt-2 rounded-lg",
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            [
              _c(
                "v-tab-item",
                { key: "1", attrs: { eager: "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "white--text mb-2",
                      attrs: { "no-gutters": "", align: "center" },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 mr-4",
                          attrs: {
                            href: `${_vm.debitUrl}&download=1`,
                            color: "success",
                            ripple: false,
                            download: "",
                            small: "",
                            tag: "a",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("mdi-download"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("button-download")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("iframe", {
                    staticStyle: { border: "1px solid #666ccc" },
                    attrs: {
                      src: _vm.debitUrl,
                      title: "Debit PDF Previewer",
                      frameborder: "1",
                      scrolling: "auto",
                      height: "800",
                      width: "100%",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: "2", attrs: { eager: "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "white--text mb-2",
                      attrs: { "no-gutters": "", align: "center" },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 mr-4",
                          attrs: {
                            href: `${_vm.noaUrl}&download=1`,
                            color: "success",
                            ripple: false,
                            download: "",
                            small: "",
                            tag: "a",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("mdi-download"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("button-download")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.debitInfo.availableFiles.includes("NOA")
                    ? _c("iframe", {
                        staticStyle: { border: "1px solid #666ccc" },
                        attrs: {
                          src: _vm.noaUrl,
                          title: "NOA PDF Previewer",
                          frameborder: "1",
                          scrolling: "auto",
                          height: "800",
                          width: "100%",
                        },
                      })
                    : _c("NoData", {
                        attrs: {
                          text: "Dữ liệu đang được cập nhật...",
                          "min-height": "800px",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }