<template>
  <div>
    <p class="display-1 mt-2 mb-5 text-uppercase">
      {{ $t('title-debit-search') }}
    </p>
    <DebitSearch />
  </div>
</template>

<script>
import DebitSearch from '@/components/shared/local/vsl/DebitSearch';
export default {
  components: { DebitSearch },
  data: () => ({}),
  computed: {},
  destroyed() {},
  methods: {},
};
</script>

<style></style>
