var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "display-1 mt-2 mb-5 text-uppercase" }, [
        _vm._v(" " + _vm._s(_vm.$t("title-debit-search")) + " "),
      ]),
      _c("DebitSearch"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }