<template>
  <div class="">
    <v-card
      class="py-1 px-2"
      rounded="lg"
    >
      <v-card-text>
        <v-form
          @submit="onSearch"
          onSubmit="return false;"
        >
          <v-row align="center">
            <v-text-field
              v-model="debitSearchNo"
              clearable
              :label="$t('label-debit-search')"
              type="text"
              prepend-inner-icon="mdi-google-nearby"
              solo
              hide-details
              item-text="name"
              item-value="code"
              :loading="isLoading"
              :readonly="isLoading"
              @click:clear="onClearSearch"
            />
            <v-btn
              class="px-10"
              large
              :class="{
                'mt-2': $vuetify.breakpoint.mobile,
              }"
              :width="$vuetify.breakpoint.mobile ? '100%' : undefined"
              :disabled="isLoading"
              color="primary"
              @click="onSearch"
            >
              <v-icon> mdi-magnify </v-icon>
              {{ $t('search') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-scroll-y-transition>
      <DebitSearchResult
        v-if="render && debitInfo.id"
        class="mt-4 mb-16"
        :loading="isLoading"
        :search-no="debitSearchNo"
      />
    </v-scroll-y-transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import DebitSearchResult from './DebitSearchResult.vue';
// import MobileDebitSearchResult from './MobileDebitSearchResult.vue';
export default {
  components: {
    DebitSearchResult,
    // MobileDebitSearchResult,
  },
  data: () => ({
    isLoading: false,
    debitSearchNo: '',
    dialog: {
      result: false,
    },
    render: false,
  }),
  computed: {
    ...mapState('vsl/debit', ['debitInfo']),
  },
  created() {
    const { debit_search_no } = this.$route.query;
    this.debitSearchNo = debit_search_no || '';
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    ...mapMutations('vsl/debit', ['RESET']),
    ...mapActions('vsl/debit', ['fetchDebitInfo']),
    async onSearch() {
      if (!this.debitSearchNo || this.debitSearchNo.length === 0) {
        await this.$router.push('').catch((e) => e);
        return;
      }
      try {
        this.isLoading = true;
        this.RESET(['debitInfo']);
        await this.$router
          .push(`?debit_search_no=${this.debitSearchNo}`)
          .catch((e) => e);
        await this.fetchDebitInfo({
          debitSearchNo: this.debitSearchNo,
        });
        this.render = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.render = true;
        if (this.$vuetify.breakpoint.mobile) {
          this.dialog.result = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onClearSearch() {
      await this.$router.push('').catch((e) => e);
    },
  },
};
</script>

<style></style>
